import PersonalizationCardImage from "./components/PersonalizationCardImage";
import PersonalizationInput from "./components/PersonalizationInput";
import PersonalizationTextarea from "./components/PersonalizationTextarea";

export default function PersonalizationThankYou({getError,surveySettingsFormData,setSurveySettingsFormData}) {

    console.log('surveySettingsFormData',surveySettingsFormData);

    return <div style={{
        display: "flex",
        flexDirection: "column",
        gap:'20px'
    }}>
        <PersonalizationCardImage src={'/assets/survey_personalization_screens/thank_you.png'} alt={'Welcome Screen'} />

        <PersonalizationInput
            title={'Title'}
            value={surveySettingsFormData?.thankSubtitle}
            setValue={(value) => setSurveySettingsFormData({thankSubtitle:value})}
            errorMessage={getError('surveySettings','thankSubtitle')}
        />

        <PersonalizationInput
            title={'Section Title'}
            value={surveySettingsFormData?.thankHeadline}
            setValue={(value) => setSurveySettingsFormData({thankHeadline:value})}
            errorMessage={getError('surveySettings','thankHeadline')}
        />

        <PersonalizationTextarea
            title={'Description'}
            value={surveySettingsFormData?.thankText}
            setValue={(value) => setSurveySettingsFormData({thankText:value})}
            errorMessage={getError('surveySettings','thankText')}
        />

        <PersonalizationInput
            title={'Video Link (Optional)'}
            value={surveySettingsFormData?.thankVideo}
            setValue={(value) => setSurveySettingsFormData({thankVideo:value})}
            errorMessage={getError('surveySettings','thankVideo')}
        />
    </div>
}
