import TextInput from "../Comon/TextInput/TextInput";
import React from "react";
import Headline from "../Comon/Headline/Headline";
import {useTranslation} from "react-i18next";

export default function SurveyAdditionalSettings({
                                                     hasMadeAnyChanges,
                                                     setHasMadeAnyChanges,
                                                     sendResultsToPatients,
                                                     setSendResultsToPatients,
                                                     sendResultsToClinic,
                                                     setSendResultsToClinic,
                                                     clinicResultsEmail,
                                                     setClinicResultsEmail,
                                                     getError

})
{
    const { i18n } = useTranslation();

    return <div>
        <div style={{marginBottom:'20px'}}>
            <Headline
                text={i18n.t("additionalSettings")}
                type={"Headline-personalization"}
            />
        </div>

        <label>
            <input
                type="checkbox"
                checked={sendResultsToPatients}
                onChange={() => setSendResultsToPatients(!sendResultsToPatients) & setHasMadeAnyChanges(true)}
            />{" "}
            Automatically Send PDF Results to Patients
        </label>
        {
            getError(null,'sendResultsToPatients') && <div
                style={{
                    color:'red',
                    marginLeft:'33px',
                    fontSize:'12px',
                    marginTop:'5px',
                }}>{getError(null,'sendResultsToPatients')}</div>
        }

        <div className="autoClinicEmail" style={{width:'100%'}}>
            <div>
                <label>
                    <input
                        type="checkbox"
                        checked={sendResultsToClinic}
                        onChange={() => setSendResultsToClinic(!sendResultsToClinic) & setHasMadeAnyChanges(true)}
                    />{" "}
                    Automatically Send PDF Results to Clinic
                </label>
                {
                    getError(null,'sendResultsToClinic') && <div
                        style={{
                            color:'red',
                            marginLeft:'33px',
                            fontSize:'12px',
                            marginTop:'5px',
                        }}>{getError(null,'sendResultsToClinic')}</div>
                }
            </div>

            {sendResultsToClinic && (
                <div>
                    <TextInput
                        hideErrorMessageIfNoMessage={true}
                        type={"email"}
                        label={i18n.t("Clinic email")}
                        value={clinicResultsEmail}
                        setValue={(value) => setClinicResultsEmail(value) & setHasMadeAnyChanges(true)}
                        mode={"grey"}
                    />
                    {
                        getError(null,'clinicResultsEmail') && <div
                            style={{
                                color:'red',
                                marginLeft:'33px',
                                fontSize:'12px',
                                marginTop:'5px',
                            }}>{getError(null,'clinicResultsEmail')}</div>
                    }
                </div>

            )}
        </div>
    </div>
}