import PersonalizationCardTabPicker from "../components/PersonalizationCardTabPicker";
import React, {useState} from "react";
import PersonalizationCardImage from "../components/PersonalizationCardImage";
import PersonalizationInput from "../components/PersonalizationInput";
import PersonalizationTextarea from "../components/PersonalizationTextarea";
import PersonalizationDivider from "../components/PersonalizationDivider";

export default function PersonalizationOverallHealthQuestions({user,overallHealths,setOverallHealths}) {

    const tabs = [
        {label:'Open Question', value:'open'},
        {label:'Grade Question', value:'grade'},
        {label:'Multiple Answers', value:'multiple'},
    ];
    const [activeTab,setActiveTab] = useState(tabs[0]);





    return <div>

        <PersonalizationCardTabPicker
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
        />

        {
            activeTab?.value === tabs?.[0]?.value && <div style={{
                display: "flex",
                flexDirection: "column",
                gap: '20px'
            }}>
                <PersonalizationCardImage src={'/assets/survey_personalization_screens/overall_health_open_question.png'} alt={'Welcome Screen'}/>

                <PersonalizationTextarea
                    title={'Question'}
                    value={overallHealths?.question4Question}
                    setValue={(value) => setOverallHealths({question4Question: value})}
                />

                <PersonalizationDivider />

                <PersonalizationCardImage src={'/assets/survey_personalization_screens/overall_health_open_question_pdf_title.png'} alt={'Welcome Screen'}/>

                <PersonalizationInput
                    title={'Question Title on PDF'}
                    value={overallHealths?.question4PdfTitle}
                    setValue={(value) => setOverallHealths({question4PdfTitle: value})}
                />


            </div>
        }

        {
            activeTab?.value === tabs?.[1]?.value && <div style={{
                display: "flex",
                flexDirection: "column",
                gap: '20px'
            }}>
                <PersonalizationCardImage src={'/assets/survey_personalization_screens/overall_health_grade_question.png'} alt={'Welcome Screen'}/>

                <PersonalizationInput
                    title={'Title'}
                    value={overallHealths?.question5Subtitle}
                    setValue={(value) => setOverallHealths({question5Subtitle: value})}
                />

                <PersonalizationTextarea
                    title={'Question/Statement'}
                    value={overallHealths?.question5Question}
                    setValue={(value) => setOverallHealths({question5Question: value})}
                />

                <div style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: '20px',
                }}>
                    <PersonalizationInput
                        title={'Low Scale'}
                        value={overallHealths?.question5LowScale}
                        setValue={(value) => setOverallHealths({question5LowScale: value})}
                    />

                    <PersonalizationInput
                        title={'High Scale'}
                        value={overallHealths?.question5HighScale}
                        setValue={(value) => setOverallHealths({question5HighScale: value})}
                    />
                </div>

                <PersonalizationDivider />

                <PersonalizationCardImage src={'/assets/survey_personalization_screens/overall_health_grade_question_pdf_title.png'} alt={'Welcome Screen'}/>

                <PersonalizationInput
                    title={'Question Title on PDF'}
                    value={overallHealths?.question5PdfTitle}
                    setValue={(value) => setOverallHealths({question5PdfTitle: value})}
                />
            </div>
        }

                {
                    activeTab?.value === tabs?.[2]?.value && <div style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: '20px'
                    }}>
                        <PersonalizationCardImage src={'/assets/survey_personalization_screens/overall_health_multiple_question.png'} alt={'Welcome Screen'}/>

                        <PersonalizationTextarea
                            title={'Question'}
                            value={overallHealths?.question6Question}
                            setValue={(value) => setOverallHealths({question6Question: value})}
                        />

                        {/*<PersonalizationTextarea*/}
                        {/*    title={'Description'}*/}
                        {/*    value={overallHealths?.question6Description}*/}
                        {/*    setValue={(value) => setOverallHealths({question6Description: value})}*/}
                        {/*/>*/}

                        <PersonalizationInput
                            title={'Answer 1'}
                            value={overallHealths?.question6Answer1}
                            setValue={(value) => setOverallHealths({question6Answer1: value})}
                        />

                        <PersonalizationInput
                            title={'Answer 2'}
                            value={overallHealths?.question6Answer2}
                            setValue={(value) => setOverallHealths({question6Answer2: value})}
                        />

                        <PersonalizationInput
                            title={'Answer 3'}
                            value={overallHealths?.question6Answer3}
                            setValue={(value) => setOverallHealths({question6Answer3: value})}
                        />

                        <PersonalizationInput
                            title={'Answer 4'}
                            value={overallHealths?.question6Answer4}
                            setValue={(value) => setOverallHealths({question6Answer4: value})}
                        />

                        <PersonalizationInput
                            title={'Answer 5'}
                            value={overallHealths?.question6Answer5}
                            setValue={(value) => setOverallHealths({question6Answer5: value})}
                        />

                        <PersonalizationDivider />

                        <PersonalizationCardImage src={'/assets/survey_personalization_screens/overall_health_multiple_question_pdf_title.png'} alt={'Welcome Screen'}/>

                        <PersonalizationInput
                            title={'Question Title on PDF'}
                            value={overallHealths?.question6PdfTitle}
                            setValue={(value) => setOverallHealths({question6PdfTitle: value})}
                        />


                    </div>
                }

            </div>
        }