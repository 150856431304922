import PersonalizationCardImage from "./components/PersonalizationCardImage";
import PersonalizationInput from "./components/PersonalizationInput";
import PersonalizationTextarea from "./components/PersonalizationTextarea";

export default function PersonalizationHealthImprovementsIntro({getError,overallHealths,setOverallHealths}) {
    return <div style={{
        display: "flex",
        flexDirection: "column",
        gap:'20px'
    }}>
        <PersonalizationCardImage src={'/assets/survey_personalization_screens/global_health_intro.png'} alt={'Welcome Screen'} />

        <PersonalizationInput
            title={'Section Title'}
            value={overallHealths?.title}
            setValue={(value) => setOverallHealths({title:value})}
            errorMessage={getError('overallHealths','title')}
        />

        <PersonalizationTextarea
            title={'Description'}
            value={overallHealths?.desc}
            setValue={(value) => setOverallHealths({desc:value})}
            errorMessage={getError('overallHealths','desc')}
        />

        <PersonalizationInput
            title={'Video Link (Optional)'}
            value={overallHealths?.videoUrl}
            setValue={(value) => setOverallHealths({videoUrl:value})}
            errorMessage={getError('overallHealths','videoUrl')}
        />
    </div>
}
