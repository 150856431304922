export default function PersonalizationCardImage({src, alt= 'Card Image'})
{
    return <img
        style={{
            width: '100%',
            height: 'auto',
            borderRadius: '20px',
            objectFit: 'contain',
            objectPosition: 'center',
        }}
        src={src}
        alt={alt}
    />
}