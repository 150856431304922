import TextInput from "../../../../Comon/TextInput/TextInput";
import React from "react";

export default function PersonalizationInput({
                                                 title = 'Title',
                                                 placeholder,
                                                 value,
                                                 setValue,
                                                 disabled = false,
                                                 errorMessage = null,
                                                labelHelpText
}) {

    return <div>
        <TextInput
            hideErrorMessageIfNoMessage={true}
            label={title}
            mode={"white"}
            placeholder={placeholder ?? 'Type here...'}
            type={"text"}
            disabled={disabled}
            setValue={(val) => setValue(val)}
            maxlength="30"
            value={value}
            labelHelpText={labelHelpText}
        />

        {
            errorMessage && (
                <div
                    style={{
                        color: 'red',
                        marginLeft: '33px',
                        fontSize: '12px',
                        marginTop: '5px',
                    }}
                >
                    {typeof errorMessage === 'string' ? (
                        <div>{errorMessage}</div>  // Render the errorMessage as a string
                    ) : (
                        errorMessage.map((item, index) => (
                            <div key={index}>{item}</div>  // Map over the array if it's an array
                        ))
                    )}
                </div>
            )
        }



    </div>

}