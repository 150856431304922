import PersonalizationCardImage from "./components/PersonalizationCardImage";
import PersonalizationInput from "./components/PersonalizationInput";
import PersonalizationTextarea from "./components/PersonalizationTextarea";
import PersonalizationDivider from "./components/PersonalizationDivider";

export default function PersonalizationHealthConcernsIntro({getError,healthConcernsFormData,setHealthConcernsFormData}) {
    return <div style={{
        display: "flex",
        flexDirection: "column",
        gap:'20px'
    }}>
        <PersonalizationCardImage src={'/assets/survey_personalization_screens/top_3_1.png'} alt={'Welcome Screen'} />

        <PersonalizationInput
            title={'Section Title'}
            value={healthConcernsFormData?.title}
            setValue={(value) => setHealthConcernsFormData({title:value})}
            errorMessage={getError('healthConcerns','title')}
        />

        <PersonalizationTextarea
            title={'Description'}
            value={healthConcernsFormData?.desc}
            setValue={(value) => setHealthConcernsFormData({desc:value})}
            errorMessage={getError('healthConcerns','desc')}
        />

        <PersonalizationInput
            title={'Video Link (Optional)'}
            value={healthConcernsFormData?.videoUrl}
            setValue={(value) => setHealthConcernsFormData({videoUrl:value})}
            errorMessage={getError('healthConcerns','videoUrl')}
        />

        <PersonalizationDivider/>

        <PersonalizationCardImage src={'/assets/survey_personalization_screens/top_3_2.png'} alt={'Welcome Screen'} />

        <PersonalizationInput
            title={'Section Title on PDF'}
            value={healthConcernsFormData?.pdfTitle}
            setValue={(value) => setHealthConcernsFormData({pdfTitle:value})}
            errorMessage={getError('healthConcerns','pdfTitle')}
        />

    </div>
}