import React, { useEffect, useState } from "react";
import "./EditableNextLifestyleChangesSurvey.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  updateSurveyData,
  updateSurveyPage,
} from "../../../../../redux/actions/survey";
import SurveyHero from "../../../../components/Survey/SurveyHero/SurveyHero";
import { Box } from "@mui/material";
import HealthConcernText from "../../../../components/Survey/HealthConcernText/HealthConcernText";
import getThemes from "../../../../../constants/colors/getTheme";
import Button from "../../../../components/Comon/Button/Button";
import LinkText from "../../../../components/Comon/LinkText/LinkText";
import {
  resetPopUp,
  setAppTheme,
  showPopup,
} from "../../../../../redux/actions/app";
import { createSurvey, updateSurvey } from "../../../../../core/axios/services";
import { logoutUser } from "../../../../../redux/actions/user";
import Loader from "../../../../components/Loader/Loader";

const EditableNextLifestyleChangesSurvey = ({
  showGlobalSnackbar,
  survey,
  i18n,
  app,
}) => {
  const [concerns, setConcerns] = useState([]);
  const [themeColor, setThemeColor] = useState([]);

  const navigate = useNavigate();
  const { code } = useParams();
  const { previousId } = useParams();
  const { surveyId } = useParams();

  const dispatch = useDispatch();
  const initialConcerns = [
    {
      name: "openEnded1",
      // topText: i18n.t("healthAndLifestyleChanges"),
      topText: survey.settings.openEndedSectionTitle,
      headline: survey.settings.openEndedQuestions[0].title,
      text: survey.settings.openEndedQuestions[0].description,
      concern:
        survey.data &&
        survey.data.healthChanges &&
        survey.data?.healthChanges[0]?.concern
          ? survey.data.healthChanges[0]?.concern
          : "",
      hasTextInput: true,
      hasRate: false,
      visible: survey.settings.openEndedQuestions[0].isActive,
      characterLimit: 180,
    },
    {
      name: "openEnded2",
      headline: survey.settings.openEndedQuestions[1].title,
      text: survey.settings.openEndedQuestions[1].description,
      concern:
        survey.data &&
        survey.data.healthChanges &&
        survey.data?.healthChanges[1]?.concern
          ? survey.data.healthChanges[1]?.concern
          : "",
      hasTextInput: true,
      hasRate: false,
      visible: survey.settings.openEndedQuestions[1].isActive,
      characterLimit: 180,
    },
    {
      name: "openEnded3",
      headline: survey.settings.openEndedQuestions[2].title,
      text: survey.settings.openEndedQuestions[2].description,
      options: [
        {
          id: 10,
          name: "yes",
          text: survey.settings.openEndedQuestions[2].options?.[0],
          selected:
            survey.data &&
            survey.data.healthChanges &&
            survey.data.healthChanges[2]?.options[0]?.selected
              ? survey.data.healthChanges[2]?.options[0]?.selected
              : false,
        },
        {
          id: 11,
          name: "no",
          text: survey.settings.openEndedQuestions[2].options?.[1],
          selected:
            survey.data &&
            survey.data.healthChanges &&
            survey.data.healthChanges[2]?.options[1]?.selected
              ? survey.data.healthChanges[2]?.options[1]?.selected
              : false,
        },
        {
          id: 12,
          name: "other",
          text:
            survey.data &&
            survey.data.healthChanges &&
            survey.data.healthChanges[2]?.options[2]?.text
              ? survey.data.healthChanges[2]?.options[2]?.text
              : survey.settings.openEndedQuestions[2].options?.[2],
          isOther: true,
          selected:
            survey.data &&
            survey.data.healthChanges &&
            survey.data.healthChanges[2]?.options[2]?.selected
              ? survey.data.healthChanges[2]?.options[2]?.selected
              : false,
        },
      ],
      hasTextInput: false,
      hasRate: false,
      singleSelect: true,
      visible: survey.settings.openEndedQuestions[2].isActive,
    },
    {
      name: "openEnded4",
      headline: survey.settings.openEndedQuestions[3].title,
      text: survey.settings.openEndedQuestions[3].description,
      options: [
        {
          id: 10,
          name: "followRecommended",
          text: survey.settings.openEndedQuestions[3].options?.[0],
          selected:
            survey.data && survey.data.healthChanges
              ? survey.data.healthChanges[3]?.options[0]?.selected
              : false,
        },
        {
          id: 11,
          name: "3timesAWeek",
          text: survey.settings.openEndedQuestions[3].options?.[1],
          selected:
            survey.data && survey.data.healthChanges
              ? survey.data.healthChanges[3]?.options[1]?.selected
              : false,
        },
        {
          id: 12,
          name: "2timesAWeek",
          text: survey.settings.openEndedQuestions[3].options?.[2],
          selected:
            survey.data && survey.data.healthChanges
              ? survey.data.healthChanges[3]?.options[2]?.selected
              : false,
        },
        {
          id: 13,
          name: "onceWeek",
          text: survey.settings.openEndedQuestions[3].options?.[3],
          selected:
            survey.data && survey.data.healthChanges
              ? survey.data.healthChanges[3]?.options[3]?.selected
              : false,
        },
        {
          id: 14,
          name: "everyTwoWeeks",
          text: survey.settings.openEndedQuestions[3].options?.[4],
          selected:
            survey.data && survey.data.healthChanges
              ? survey.data.healthChanges[3]?.options[4]?.selected
              : false,
        },

        {
          id: 16,
          name: "onceMonth",
          text: survey.settings.openEndedQuestions[3].options?.[5],
          selected:
            survey.data && survey.data.healthChanges
              ? survey.data.healthChanges[3]?.options[5]?.selected
              : false,
        },
        {
          id: 17,
          name: "other",
          text:
            survey.data && survey.data.healthChanges
              ? survey.data.healthChanges[3]?.options[6]?.text
              : survey.settings.openEndedQuestions[3].options?.[6],
          isOther: true,
          selected:
            survey.data && survey.data.healthChanges
              ? survey.data.healthChanges[3]?.options[6]?.selected
              : false,
        },
      ],
      hasTextInput: false,
      hasRate: false,
      singleSelect: true,
      visible: survey.settings.openEndedQuestions[3].isActive,
    },
    {
      name: "openEnded5",
      headline: survey.settings.openEndedQuestions[4].title,
      text: survey.settings.openEndedQuestions[4].description,
      concernRate:
        survey.data &&
        survey.data.healthChanges &&
        survey.data?.healthChanges[4]?.concernRate
          ? survey.data.healthChanges[4]?.concernRate
          : 0,
      hasTextInput: false,
      hasRate: true,
      hasSpecificRate: true,
      visible: survey.settings.openEndedQuestions[4].isActive,
    },
    {
      name: "openEnded6",
      headline: survey.settings.openEndedQuestions[5].title,
      text: survey.settings.openEndedQuestions[5].description,
      concern:
        survey.data &&
        survey.data.healthChanges &&
        survey.data?.healthChanges[5]?.concern
          ? survey.data.healthChanges[5]?.concern
          : "",
      hasTextInput: true,
      hasRate: false,
      visible: false, //in beginning is always false, and if previous question is rated less than 5 it will show up
      characterLimit: 180,
    },
    {
      name: "openEnded7",
      headline: survey.settings.openEndedQuestions[6].title,
      concernRate:
        survey.data &&
        survey.data.healthChanges &&
        survey.data?.healthChanges[6]?.concernRate
          ? Number(survey.data.healthChanges[6]?.concernRate)
          : 0,
      hasTextInput: false,
      text: survey.settings.openEndedQuestions[6].description,
      hasRate: true,
      hasSpecificRate: false,
      visible: survey.settings.openEndedQuestions[6].isActive,
    },
    {
      name: "openEnded8",
      headline: survey.settings.openEndedQuestions[7].title,
      text: survey.settings.openEndedQuestions[7].description,
      concern:
        survey.data &&
        survey.data.healthChanges &&
        survey.data?.healthChanges[7]?.concern
          ? survey.data.healthChanges[7]?.concern
          : "",
      hasTextInput: true,
      hasRate: false,
      visible: survey.settings.openEndedQuestions[7].isActive,
      separatedInParts: 3,
      characterLimit: 180,
    },
    {
      name: "openEnded9",
      headline: survey.settings.openEndedQuestions[8].title,
      text: survey.settings.openEndedQuestions[8].description,
      concern:
        survey.data &&
        survey.data.healthChanges &&
        survey.data?.healthChanges[8]?.concern
          ? survey.data.healthChanges[8]?.concern
          : "",
      hasTextInput: true,
      hasRate: false,
      visible: survey.settings.openEndedQuestions[8].isActive,
      characterLimit: 180,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(updateSurveyPage(3));
  }, []);

  useEffect(() => {
    setConcerns(initialConcerns);

    if (survey.data == null) {
      survey.data = { healthChanges: initialConcerns };
    } else {
      survey.data.healthChanges = initialConcerns;
    }

    if (
      survey.data.healthChanges[4].concernRate < 5 &&
      survey.data.healthChanges[4].concernRate > 0
    ) {
      if (survey.settings.openEndedQuestions[5].isActive) {
        survey.data.healthChanges[5].visible = true;
      }
    } else {
      survey.data.healthChanges[5].visible = false;
    }

    dispatch(updateSurveyData(survey.data));
  }, []);

  useEffect(() => {
    if (app.popupStatus === true) {
      switch (app.popUpData?.type) {
        case "editSurvey":
          sendSurveyToApi();
          dispatch(resetPopUp());
          break;
      }
    } else if (app.popupStatus === false) {
      dispatch(resetPopUp());
    }

    setThemeColor(getThemes("mainColor", app.appTheme));
  }, [app]);

   const [isSendingSurveyToApi, setIsSendingSurveyToApi] = useState(false);
  const sendSurveyToApi = async () => {
    setIsSendingSurveyToApi(true);
    try {
      return await updateSurvey(survey).then((res) => {
        setIsSendingSurveyToApi(false);
        dispatch(updateSurveyPage(5));
        navigate(
          "/survey-next-edit/" +
            code +
            "/" +
            surveyId +
            "/" +
            previousId +
            "/thank-you"
        );
      });
    } catch (error) {
      setIsSendingSurveyToApi(false);
      if (error.response.data.errorMessage) {
        showGlobalSnackbar(error.response.data.errorMessage);
      } else {
        showGlobalSnackbar(i18n.t("thereWasAnError"));
      }
    }
  };

  const nextPage = () => {
    const data = {
      showPopup: true,
      data: {
        title: i18n.t("confirmEditSurvey"),
        confirmButton: i18n.t("confirmEditSurveyButton"),
        denyButton: i18n.t("denyEditSurveyButton"),
        type: "editSurvey",
      },
    };
    dispatch(showPopup(data));
  };

  const previousPage = () => {
    dispatch(updateSurveyPage(2));
    navigate(
      "/survey-next-edit/" +
        code +
        "/" +
        surveyId +
        "/" +
        previousId +
        "/global-improvements"
    );
  };

  const updateConcern = (index, value, type) => {
    if (type === "text") {
      concerns[index].concern = value;
    } else {
      concerns[index].concernRate = value;
    }

    setConcerns([...concerns]);

    if (survey.data == null) {
      survey.data = { healthChanges: concerns };
    } else {
      survey.data.healthChanges = concerns;
    }

    if (
      survey.data.healthChanges[4].concernRate < 5 &&
      survey.data.healthChanges[4].concernRate > 0
    ) {
      if (survey.settings.openEndedQuestions[5].isActive) {
        survey.data.healthChanges[5].visible = true;
      }
    } else {
      survey.data.healthChanges[5].visible = false;
    }

    dispatch(updateSurveyData(survey.data));
  };

  const updateOptions = (data, index) => {
    concerns[index].options = data;
    setConcerns([...concerns]);

    if (survey.data == null) {
      survey.data = { healthChanges: concerns };
    } else {
      survey.data.healthChanges = concerns;
    }
    dispatch(updateSurveyData(survey.data));
  };

  const scrollTo = () => {
    const element = document.getElementById(concerns[0].name);
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  return (
    <div className="ThirdScreenSurvey">
      <SurveyHero
        // step={i18n.t("step") + " 3"}
        // headline={i18n.t("healthAndLifestyleChanges")}
        headline={survey.settings.openEndedSectionTitle}
        buttonText={i18n.t("startSurvey")}
        clickedButton={() => scrollTo()}
        app={app}
        surveyType={survey?.settings?.type}
      />

      <div className={"concernsContainer"}>
        {concerns.map((concern, index) => {
          return (
            <div className={"concernContainer"} key={index}>
              {concern != null && concern.visible !== false ? (
                <HealthConcernText
                  setConcernText={(value) =>
                    updateConcern(index, value, "text")
                  }
                  setConcernRate={(value) =>
                    updateConcern(index, value, "rate")
                  }
                  mandatory={concern.mandatory}
                  text={concern.text}
                  topText={concern.topText}
                  headline={concern.headline}
                  themeColor={themeColor}
                  hasTextInput={concern.hasTextInput}
                  leftOption={concern.leftOption}
                  rightOption={concern.rightOption}
                  id={concern.name}
                  hasRate={concern.hasRate}
                  concernRate={concern.concernRate}
                  options={concern.options}
                  setOptions={(data) => updateOptions(data, index)}
                  i18n={i18n}
                  concernText={concern.concern}
                  hasSpecificRate={concern.hasSpecificRate}
                  singleSelect={concern.singleSelect}
                  characterLimit={concern.characterLimit}
                  separatedInParts={concern.separatedInParts}
                />
              ) : null}
            </div>
          );
        })}
      </div>
      {
          isSendingSurveyToApi && <Loader/>
      }
      <div className={"buttonsContainer"}>
        <div className={"nextContainer"} onClick={() => nextPage()}>
          <Button
              disabled={isSendingSurveyToApi}
            color={themeColor}
            mode={"small"}
            text={i18n.t("completeSurvey")}
          />
        </div>
        <div className={"previousContainer"} onClick={() => previousPage()}>
          <LinkText text={i18n.t("previousStep")} />
        </div>
      </div>
    </div>
  );
};

export default EditableNextLifestyleChangesSurvey;
