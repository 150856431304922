import PersonalizationCardTabPicker from "../components/PersonalizationCardTabPicker";
import React, {useState} from "react";
import PersonalizationCardImage from "../components/PersonalizationCardImage";
import PersonalizationInput from "../components/PersonalizationInput";
import PersonalizationTextarea from "../components/PersonalizationTextarea";

export default function PersonalizationOverallHealthLevels({user,overallHealths,setOverallHealths}) {

    const tabs = [
        {label:'Current Level', value:'current'},
        {label:'Desired Level', value:'desired'},
    ];
    const [activeTab,setActiveTab] = useState(tabs[0]);


    return <div>

        <PersonalizationCardTabPicker
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
        />

        {
            activeTab?.value === tabs?.[0]?.value && <div style={{
                display: "flex",
                flexDirection: "column",
                gap: '20px'
            }}>
                <PersonalizationCardImage src={'/assets/survey_personalization_screens/current.png'} alt={'Welcome Screen'}/>

                <PersonalizationInput
                    title={'Title'}
                    value={overallHealths?.question1Subtitle}
                    setValue={(value) => setOverallHealths({question1Subtitle: value})}
                />

                <PersonalizationInput
                    title={'Question/Statement'}
                    value={overallHealths?.question1Title}
                    setValue={(value) => setOverallHealths({question1Title: value})}
                />

                {/*<PersonalizationTextarea*/}
                {/*    title={'Description'}*/}
                {/*    value={overallHealths?.question1Description}*/}
                {/*    setValue={(value) => setOverallHealths({question1Description: value})}*/}
                {/*/>*/}

                <div style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: '20px',
                }}>
                    <PersonalizationInput
                        title={'Low Scale'}
                        value={overallHealths?.question1LowScale}
                        setValue={(value) => setOverallHealths({question1LowScale: value})}
                    />
                    <PersonalizationInput
                        title={'High Scale'}
                        value={overallHealths?.question1HighScale}
                        setValue={(value) => setOverallHealths({question1HighScale: value})}
                    />
                </div>
            </div>
        }

        {
            activeTab?.value === tabs?.[1]?.value && <div style={{
                display: "flex",
                flexDirection: "column",
                gap: '20px'
            }}>
                <PersonalizationCardImage src={'/assets/survey_personalization_screens/desired.png'} alt={'Welcome Screen'}/>

                <PersonalizationInput
                    title={'Title'}
                    value={overallHealths?.question2Subtitle}
                    setValue={(value) => setOverallHealths({question2Subtitle: value})}
                />

                <PersonalizationInput
                    title={'Question/Statement'}
                    value={overallHealths?.question2Title}
                    setValue={(value) => setOverallHealths({question2Title: value})}
                />

                {/*<PersonalizationTextarea*/}
                {/*    title={'Description'}*/}
                {/*    value={overallHealths?.question2Description}*/}
                {/*    setValue={(value) => setOverallHealths({question2Description: value})}*/}
                {/*/>*/}

                <div style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: '20px',
                }}>
                    <PersonalizationInput
                        title={'Low Scale'}
                        value={overallHealths?.question2LowScale}
                        setValue={(value) => setOverallHealths({question2LowScale: value})}
                    />
                    <PersonalizationInput
                        title={'High Scale'}
                        value={overallHealths?.question2HighScale}
                        setValue={(value) => setOverallHealths({question2HighScale: value})}
                    />
                </div>
            </div>
        }

    </div>
}