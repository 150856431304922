import React from "react";

export default function PersonalizationButton({text,...rest}) {
    return <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }}>
        <div
            style={{
                cursor: "pointer",
                fontSize:'14px',
                minWidth:'200px',
                borderRadius: "23px",
                border: "1px solid rgba(97, 132, 157, 1)",
                color: "rgba(97, 132, 157, 1)",
                fontFamily: "HelveticaNeue-Medium",
                fontWeight: "500",
                fontStyle: "Medium",
                letterSpacing: "0px",
                textAlign: "center",
                padding:'5px 20px'
            }}
            {...rest}
        >
            {text}
        </div>
    </div>
}