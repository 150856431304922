import PersonalizationCardImage from "./components/PersonalizationCardImage";
import PersonalizationInput from "./components/PersonalizationInput";

export default function PersonalizationPatientIntro({getError, surveySettingsFormData,setSurveySettingsFormData}) {
    return <div style={{
        display: "flex",
        flexDirection: "column",
        gap:'20px'
    }}>
        <PersonalizationCardImage src={'/assets/survey_personalization_screens/patient_1.png'} alt={'Welcome Screen'} />

        <PersonalizationInput
            title={'Section Title'}
            value={surveySettingsFormData?.patientHeadline}
            setValue={(value) => setSurveySettingsFormData({patientHeadline:value})}
            errorMessage={getError('surveySettings','patientHeadline')}
        />
    </div>
}
