import PersonalizationCardImage from "./components/PersonalizationCardImage";
import PersonalizationInput from "./components/PersonalizationInput";
import PersonalizationTextarea from "./components/PersonalizationTextarea";

export default function PersonalizationWelcomeBack({getError,surveySettingsFormData,setSurveySettingsFormData}) {
    return <div style={{
        display: "flex",
        flexDirection: "column",
        gap:'20px'
    }}>
        <PersonalizationCardImage src={'/assets/survey_personalization_screens/welcome_back.png'} alt={'Welcome Screen'} />

        <PersonalizationInput
            title={'Title'}
            value={surveySettingsFormData?.nextIntroSubtitle}
            setValue={(value) => setSurveySettingsFormData({nextIntroSubtitle:value})}
            errorMessage={getError('surveySettings','nextIntroSubtitle')}
        />

        <PersonalizationInput
            title={'Section Title'}
            value={surveySettingsFormData?.nextIntroHeadline}
            setValue={(value) => setSurveySettingsFormData({nextIntroHeadline:value})}
            errorMessage={getError('surveySettings','nextIntroHeadline')}
        />

        <PersonalizationTextarea
            title={'Description'}
            value={surveySettingsFormData?.nextIntroText}
            setValue={(value) => setSurveySettingsFormData({nextIntroText:value})}
            errorMessage={getError('surveySettings','nextIntroText')}
        />

        <PersonalizationInput
            title={'Video Link (Optional)'}
            value={surveySettingsFormData?.nextIntroVideo}
            setValue={(value) => setSurveySettingsFormData({nextIntroVideo:value})}
            errorMessage={getError('surveySettings','nextIntroVideo')}
        />
    </div>
}
