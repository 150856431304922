import PersonalizationCardImage from "./components/PersonalizationCardImage";
import PersonalizationInput from "./components/PersonalizationInput";
import PersonalizationTextarea from "./components/PersonalizationTextarea";
import {useState} from "react";
import PersonalizationCardTabPicker from "./components/PersonalizationCardTabPicker";
import PersonalizationDivider from "./components/PersonalizationDivider";

export default function PersonalizationHealthConcernsLifeImpact({
                                                                    getError,
                                                                    healthConcernsFormData,
                                                                    setHealthConcernsFormData,
                                                                    lifeImpactsFormData,
                                                                    setLifeImpactsFormData
}) {

    const tabs = [
        {label:'First HC', value:'first_hc'},
        {label:'Second HC', value:'second_hc'},
        {label:'Third HC', value:'third_hc'},
        {label:'Life Impact', value:'life_impact'},
    ];
    const [activeTab,setActiveTab] = useState(tabs[0]);










    return <div style={{
        display: "flex",
        flexDirection: "column",
        gap:'20px'
    }}>
        <PersonalizationCardTabPicker
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
        />

        {
            activeTab?.value === tabs?.[0]?.value && <div style={{
                display: "flex",
                flexDirection: "column",
                gap: '20px'
            }}>
                <PersonalizationCardImage src={'/assets/survey_personalization_screens/top_3_first.png'} alt={'Welcome Screen'}/>

                <PersonalizationInput
                    title={'Title'}
                    value={healthConcernsFormData?.question1Subtitle}
                    setValue={(value) => setHealthConcernsFormData({question1Subtitle:value})}
                    errorMessage={getError('healthConcerns','question1Subtitle')}
                />

                <PersonalizationTextarea
                    title={'Question/Statement'}
                    value={healthConcernsFormData?.question1Title}
                    setValue={(value) => setHealthConcernsFormData({question1Title:value})}
                    errorMessage={getError('healthConcerns','question1Title')}
                />

                <PersonalizationTextarea
                    title={'Description'}
                    value={healthConcernsFormData?.question1Description}
                    setValue={(value) => setHealthConcernsFormData({question1Description:value})}
                    errorMessage={getError('healthConcerns','question1Description')}
                />

                <div style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap:'20px',
                }}>
                    <PersonalizationInput
                        title={'Low Scale'}
                        value={healthConcernsFormData?.question1LowScale}
                        setValue={(value) => setHealthConcernsFormData({question1LowScale:value})}
                        errorMessage={getError('healthConcerns','question1LowScale')}
                    />
                    <PersonalizationInput
                        title={'High Scale'}
                        value={healthConcernsFormData?.question1HighScale}
                        setValue={(value) => setHealthConcernsFormData({question1HighScale:value})}
                        errorMessage={getError('healthConcerns','question1HighScale')}
                    />
                </div>
            </div>
        }

        {
            activeTab?.value === tabs?.[1]?.value && <div style={{
                display: "flex",
                flexDirection: "column",
                gap: '20px'
            }}>
                <PersonalizationCardImage src={'/assets/survey_personalization_screens/top_3_second.png'} alt={'Welcome Screen'}/>

                <PersonalizationInput
                    title={'Title'}
                    value={healthConcernsFormData?.question2Subtitle}
                    setValue={(value) => setHealthConcernsFormData({question2Subtitle:value})}
                    errorMessage={getError('healthConcerns','question2Subtitle')}
                />

                <PersonalizationTextarea
                    title={'Question/Statement'}
                    value={healthConcernsFormData?.question2Title}
                    setValue={(value) => setHealthConcernsFormData({question2Title:value})}
                    errorMessage={getError('healthConcerns','question2Title')}
                />

                <PersonalizationTextarea
                    title={'Description'}
                    value={healthConcernsFormData?.question2Description}
                    setValue={(value) => setHealthConcernsFormData({question2Description:value})}
                    errorMessage={getError('healthConcerns','question2Description')}
                />

                <div style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap:'20px',
                }}>
                    <PersonalizationInput
                        title={'Low Scale'}
                        value={healthConcernsFormData?.question2LowScale}
                        setValue={(value) => setHealthConcernsFormData({question2LowScale:value})}
                        errorMessage={getError('healthConcerns','question2LowScale')}
                    />
                    <PersonalizationInput
                        title={'High Scale'}
                        value={healthConcernsFormData?.question2HighScale}
                        setValue={(value) => setHealthConcernsFormData({question2HighScale:value})}
                        errorMessage={getError('healthConcerns','question2HighScale')}
                    />
                </div>
            </div>
        }

        {
            activeTab?.value === tabs?.[2]?.value && <div style={{
                display: "flex",
                flexDirection: "column",
                gap: '20px'
            }}>
                <PersonalizationCardImage src={'/assets/survey_personalization_screens/top_3_third.png'} alt={'Welcome Screen'}/>

                <PersonalizationInput
                    title={'Title'}
                    value={healthConcernsFormData?.question3Subtitle}
                    setValue={(value) => setHealthConcernsFormData({question3Subtitle:value})}
                    errorMessage={getError('healthConcerns','question3Subtitle')}
                />

                <PersonalizationTextarea
                    title={'Question/Statement'}
                    value={healthConcernsFormData?.question3Title}
                    setValue={(value) => setHealthConcernsFormData({question3Title:value})}
                    errorMessage={getError('healthConcerns','question3Title')}
                />

                <PersonalizationTextarea
                    title={'Description'}
                    value={healthConcernsFormData?.question3Description}
                    setValue={(value) => setHealthConcernsFormData({question3Description:value})}
                    errorMessage={getError('healthConcerns','question3Description')}
                />

                <div style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap:'20px',
                }}>
                    <PersonalizationInput
                        title={'Low Scale'}
                        value={healthConcernsFormData?.question3LowScale}
                        setValue={(value) => setHealthConcernsFormData({question3LowScale:value})}
                        errorMessage={getError('healthConcerns','question3LowScale')}
                    />
                    <PersonalizationInput
                        title={'High Scale'}
                        value={healthConcernsFormData?.question3HighScale}
                        setValue={(value) => setHealthConcernsFormData({question3HighScale:value})}
                        errorMessage={getError('healthConcerns','question3HighScale')}
                    />
                </div>
            </div>
        }

        {
            activeTab?.value === tabs?.[3]?.value && <div style={{
                display: "flex",
                flexDirection: "column",
                gap: '20px'
            }}>
                <PersonalizationCardImage src={'/assets/survey_personalization_screens/life_impact.png'} alt={'Welcome Screen'}/>

                <PersonalizationInput
                    title={'Title'}
                    value={lifeImpactsFormData?.question1Subtitle}
                    setValue={(value) => setLifeImpactsFormData({question1Subtitle: value})}
                    errorMessage={getError('lifeImpacts','question1Subtitle')}
                />

                <PersonalizationTextarea
                    title={'Question/Statement'}
                    value={lifeImpactsFormData?.question1Title}
                    setValue={(value) => setLifeImpactsFormData({question1Title: value})}
                    errorMessage={getError('lifeImpacts','question1Title')}
                />

                <PersonalizationTextarea
                    title={'Description'}
                    value={lifeImpactsFormData?.question1Description}
                    setValue={(value) => setLifeImpactsFormData({question1Description: value})}
                    errorMessage={getError('lifeImpacts','question1Description')}
                />

                <div style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap:'20px',
                }}>
                    <PersonalizationInput
                        title={'Low Scale'}
                        value={lifeImpactsFormData?.question1LowScale}
                        setValue={(value) => setLifeImpactsFormData({question1LowScale: value})}
                        errorMessage={getError('lifeImpacts','question1LowScale')}
                    />
                    <PersonalizationInput
                        title={'High Scale'}
                        value={lifeImpactsFormData?.question1HighScale}
                        setValue={(value) => setLifeImpactsFormData({question1HighScale: value})}
                        errorMessage={getError('lifeImpacts','question1HighScale')}
                    />
                </div>

                <PersonalizationDivider/>

                <PersonalizationCardImage src={'/assets/survey_personalization_screens/life_impact_pdf.png'} alt={'Welcome Screen'}/>

                <PersonalizationInput
                    title={'Title on PDF'}
                    value={lifeImpactsFormData?.pdfTitle}
                    setValue={(value) => setLifeImpactsFormData({pdfTitle: value})}
                    errorMessage={getError('lifeImpacts','pdfTitle')}
                />

                <div style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap:'20px',
                }}>
                    <PersonalizationInput
                        title={'To on PDF'}
                        value={lifeImpactsFormData?.pdfFrom}
                        setValue={(value) => setLifeImpactsFormData({pdfFrom: value})}
                        errorMessage={getError('lifeImpacts','pdfFrom')}
                    />
                    <PersonalizationInput
                        title={'From on PDF'}
                        value={lifeImpactsFormData?.pdfTo}
                        setValue={(value) => setLifeImpactsFormData({pdfTo: value})}
                        errorMessage={getError('lifeImpacts','pdfTo')}
                    />
                </div>
            </div>
        }
    </div>
}
