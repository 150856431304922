import PersonalizationInput from "../components/PersonalizationInput";
import PersonalizationCardImage from "../components/PersonalizationCardImage";

export default function PersonalizationHealthMetricOverallHealthPdfTitle({getError,surveySettingsFormData,setSurveySettingsFormData}) {
    return <div style={{
        display: "flex",
        flexDirection: "column",
        gap:'20px'
    }}>
        <PersonalizationCardImage src={'/assets/survey_personalization_screens/health_pdf_title.png'} alt={'Welcome Screen'} />

        <PersonalizationInput
            title={'Section Title on PDF:'}
            value={surveySettingsFormData?.healthMetricsPdfTitle}
            setValue={(value) => setSurveySettingsFormData({healthMetricsPdfTitle: value})}
            error={getError('surveySettings','healthMetricsPdfTitle')}
        />
    </div>
}