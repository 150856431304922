import PersonalizationCardImage from "./components/PersonalizationCardImage";
import PersonalizationInput from "./components/PersonalizationInput";
import PersonalizationTextarea from "./components/PersonalizationTextarea";
import PersonalizationDivider from "./components/PersonalizationDivider";

export default function PersonalizationStressesIntro({getError,surveySettingsFormData,setSurveySettingsFormData}) {

    return <div style={{
        display: "flex",
        flexDirection: "column",
        gap:'20px'
    }}>
        <PersonalizationCardImage src={'/assets/survey_personalization_screens/stresses_1.png'} alt={'Welcome Screen'} />

        <PersonalizationInput
            title={'Section Title'}
            value={surveySettingsFormData?.stressesHeadline}
            setValue={(value) => setSurveySettingsFormData({stressesHeadline:value})}
            errorMessage={getError('surveySettings','stressesHeadline')}
        />

        <PersonalizationTextarea
            title={'Description'}
            value={surveySettingsFormData?.stressesText}
            setValue={(value) => setSurveySettingsFormData({stressesText:value})}
            errorMessage={getError('surveySettings','stressesText')}
        />

        <PersonalizationInput
            title={'Video Link (Optional)'}
            value={surveySettingsFormData?.stressesVideo}
            setValue={(value) => setSurveySettingsFormData({stressesVideo:value})}
            errorMessage={getError('surveySettings','stressesVideo')}
        />

        <PersonalizationDivider/>

        <PersonalizationCardImage src={'/assets/survey_personalization_screens/stresses_2.png'} alt={'Welcome Screen'} />

        <PersonalizationInput
            title={'Section Title on PDF'}
            value={surveySettingsFormData?.stressesPdfTitle}
            setValue={(value) => setSurveySettingsFormData({stressesPdfTitle:value})}
            errorMessage={getError('surveySettings','stressesPdfTitle')}

        />
    </div>
}
