import PersonalizationCardImage from "./components/PersonalizationCardImage";
import PersonalizationInput from "./components/PersonalizationInput";
import PersonalizationTextarea from "./components/PersonalizationTextarea";
import PersonalizationDivider from "./components/PersonalizationDivider";

export default function PersonalizationOpenEndedIntro({getError,surveySettingsFormData,setSurveySettingsFormData}) {
    return <div style={{
        display: "flex",
        flexDirection: "column",
        gap:'20px'
    }}>
        <PersonalizationCardImage src={'/assets/survey_personalization_screens/open_ended.png'} alt={'Welcome Screen'} />

        <PersonalizationInput
            title={'Section Title'}
            value={surveySettingsFormData?.openEndedSectionTitle}
            setValue={(value) => setSurveySettingsFormData({openEndedSectionTitle: value})}
            errorMessage={getError('surveySettings','openEndedSectionTitle')}
        />

        <PersonalizationDivider/>

        <PersonalizationCardImage src={'/assets/survey_personalization_screens/open_ended_pdf.png'} alt={'Welcome Screen'} />

        <PersonalizationInput
            title={'Section Title on PDF'}
            value={surveySettingsFormData?.openEndedPdfTitle}
            setValue={(value) => setSurveySettingsFormData({openEndedPdfTitle: value})}
            errorMessage={getError('surveySettings','openEndedPdfTitle')}
        />
    </div>
}
