import Headline from "../../Comon/Headline/Headline";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import PersonalizationHealthConcernsIntro from "./screens/PersonalizationHealthConcernsIntro";
import PersonalizationHealthConcernsLifeImpact from "./screens/PersonalizationHealthConcernsLifeImpact";
import PersonalizationHealthImprovementsIntro from "./screens/PersonalizationHealthImprovementsIntro";
import PersonalizationOpenEndedQuestion from "./screens/PersonalizationOpenEndedQuestion";
import PersonalizationOpenEndedIntro from "./screens/PersonalizationOpenEndedIntro";
import PersonalizationPatientIntro from "./screens/PersonalizationPatientIntro";
import PersonalizationStresses from "./screens/PersonalizationStresses";
import PersonalizationStressesIntro from "./screens/PersonalizationStressesIntro";
import PersonalizationThankYou from "./screens/PersonalizationThankYou";
import PersonalizationWelcomeBack from "./screens/PersonalizationWelcomeBack";
import PersonalizationWelcomeScreen from "./screens/PersonalizationWelcomeScreen";
import PersonalizationHealthMetricOverallHealthPdfTitle
    from "./screens/HealthMetricOverallHealth/PersonalizationHealthMetricOverallHealthPdfTitle";
import PersonalizationHealthMetric from "./screens/HealthMetricOverallHealth/PersonalizationHealthMetric";
import PersonalizationOverallHealthLevels from "./screens/HealthMetricOverallHealth/PersonalizationOverallHealthLevels";
import PersonalizationOverallHealthQuestions
    from "./screens/HealthMetricOverallHealth/PersonalizationOverallHealthQuestions";

export default function SurveySettingsPersonalizationScreenPicker({surveyType,activeScreen, setActiveScreen, currentlyEditingSurveySettings})
{
    const { i18n } = useTranslation();

    function getHealthMetricName(order) {
        const title = currentlyEditingSurveySettings?.bodyMetrics?.find(
            (item) => item?.order === order
        )?.title;

        // Convert to sentence case if title exists
        return title
            ? title.charAt(0).toUpperCase() + title.slice(1).toLowerCase()
            : undefined;
    }



    const screens = [
        {
            label:'Welcome',
            value:'welcome',
            screen:PersonalizationWelcomeScreen,
        },
        {
            label:'Patient Intro',
            value:'patient_intro',
            screen:PersonalizationPatientIntro,
        },
        {
            label:'Stresses Intro',
            value:'stresses_intro',
            screen:PersonalizationStressesIntro,
            hideScreen:surveyType !== 2,
        },
        {
            label:'Stresses',
            value:'stresses',
            screen:PersonalizationStresses,
            hideScreen:surveyType !== 2,
        },
        {
            label:'Top 3 Health Concerns Intro',
            value:'top3_health_concerns_intro',
            screen:PersonalizationHealthConcernsIntro,
        },
        {
            label:'Top 3 Health Concerns & Life Impact',
            value:'top3_health_concerns_life_impact',
            screen:PersonalizationHealthConcernsLifeImpact,
        },
        {
            label:'Global Health Improvements Intro',
            value:'global_health_improvements_intro',
            screen:PersonalizationHealthImprovementsIntro,
        },
        {
            label:surveyType === 2 ? 'Health Metrics & Overall Health' : 'Health Metrics',
            value:'health_metrics_overall_health',
            items:[
                {
                    parent:'health_metrics_overall_health',
                    label:'PDF Title',
                    value:'hmoh_pdf_title',
                    screen:PersonalizationHealthMetricOverallHealthPdfTitle,
                },
                {
                    parent:'health_metrics_overall_health',
                    label:getHealthMetricName(1) ?? 'Neck',
                    value:'hmoh_neck',
                    screen:PersonalizationHealthMetric,
                    screenData:{
                        healthMetricOrder:1
                    }
                },
                {
                    parent:'health_metrics_overall_health',
                    label:getHealthMetricName(2) ?? 'Mid Back',
                    value:'hmoh_mid_back',
                    screen:PersonalizationHealthMetric,
                    screenData:{
                        healthMetricOrder:2
                    }
                },
                {
                    parent:'health_metrics_overall_health',
                    label:getHealthMetricName(3) ?? 'Lower Back',
                    value:'hmoh_lower_back',
                    screen:PersonalizationHealthMetric,
                    screenData:{
                        healthMetricOrder:3
                    }
                },
                {
                    parent:'health_metrics_overall_health',
                    label:getHealthMetricName(4) ?? 'Posture',
                    value:'hmoh_posture',
                    screen:PersonalizationHealthMetric,
                    screenData:{
                        healthMetricOrder:4
                    }
                },
                {
                    parent:'health_metrics_overall_health',
                    label:getHealthMetricName(5) ?? 'Energy Levels',
                    value:'hmoh_energy_levels',
                    screen:PersonalizationHealthMetric,
                    screenData:{
                        healthMetricOrder:5
                    }
                },
                {
                    parent:'health_metrics_overall_health',
                    label:getHealthMetricName(6) ?? 'Ability to Handle Stress',
                    value:'hmoh_ability_to_handle_stress',
                    screen:PersonalizationHealthMetric,
                    screenData:{
                        healthMetricOrder:6
                    }
                },
                {
                    parent:'health_metrics_overall_health',
                    label:getHealthMetricName(7) ?? 'Quality of Sleep',
                    value:'hmoh_quality_of_sleep',
                    screen:PersonalizationHealthMetric,
                    screenData:{
                        healthMetricOrder:7
                    }
                },
                {
                    parent:'health_metrics_overall_health',
                    label:getHealthMetricName(8) ?? 'Immune System',
                    value:'hmoh_imune_system',
                    screen:PersonalizationHealthMetric,
                    screenData:{
                        healthMetricOrder:8
                    }
                },
                {
                    parent:'health_metrics_overall_health',
                    label:getHealthMetricName(9) ?? 'Mental State',
                    value:'hmoh_mental_state',
                    screen:PersonalizationHealthMetric,
                    screenData:{
                        healthMetricOrder:9
                    }
                },
                {
                    parent:'health_metrics_overall_health',
                    label:getHealthMetricName(10) ?? 'Emotional State',
                    value:'hmoh_emotional_state',
                    screen:PersonalizationHealthMetric,
                    screenData:{
                        healthMetricOrder:10
                    }
                },
                {
                    parent:'health_metrics_overall_health',
                    label:'Overall Health: Levels',
                    value:'hmoh_overall_health_levels',
                    screen:PersonalizationOverallHealthLevels,
                    hideScreen:surveyType !== 2,
                },
                {
                    parent:'health_metrics_overall_health',
                    label:'Overall Health: Questions',
                    value:'hmoh_overall_health_questions',
                    screen:PersonalizationOverallHealthQuestions,
                    hideScreen:surveyType !== 2,
                },
            ],
        },
        {
            label:'Thank You (for all surveys)',
            value:'thank_you_for_all_surveys',
            screen:PersonalizationThankYou,
        },
        {
            label:'Welcome Back (Survey 2-6)',
            value:'welcome_back_survey2_6',
            screen:PersonalizationWelcomeBack,
            hideScreen:surveyType === 3,
        },
        {
            label:'Open Ended Questions Intro',
            value:'open_ended_questions_intro',
            screen:PersonalizationOpenEndedIntro,
        },
        {
            label:'Open Ended Questions',
            value:'open_ended_questions',
            items:[
                {
                    parent:'open_ended_questions',
                    label:'Question 1',
                    value:'oe_question_1',
                    screen:PersonalizationOpenEndedQuestion,
                    screenData:{
                        questionOrder:0
                    }
                },
                {
                    parent:'open_ended_questions',
                    label:'Question 2',
                    value:'oe_question_2',
                    screen:PersonalizationOpenEndedQuestion,
                    screenData:{
                        questionOrder:1
                    }
                },
                {
                    parent:'open_ended_questions',
                    label:'Question 3',
                    value:'oe_question_3',
                    screen:PersonalizationOpenEndedQuestion,
                    screenData:{
                        questionOrder:2
                    }
                },
                {
                    parent:'open_ended_questions',
                    label:'Question 4',
                    value:'oe_question_4',
                    screen:PersonalizationOpenEndedQuestion,
                    screenData:{
                        questionOrder:3
                    }
                },
                {
                    parent:'open_ended_questions',
                    label:'Question 5',
                    value:'oe_question_5',
                    screen:PersonalizationOpenEndedQuestion,
                    screenData:{
                        questionOrder:6
                    }
                },
                {
                    parent:'open_ended_questions',
                    label:'Question 6',
                    value:'oe_question_6',
                    screen:PersonalizationOpenEndedQuestion,
                    screenData:{
                        questionOrder:7
                    }
                },
                {
                    parent:'open_ended_questions',
                    label:'Question 7',
                    value:'oe_question_7',
                    screen:PersonalizationOpenEndedQuestion,
                    screenData:{
                        questionOrder:8
                    }
                },
                {
                    parent:'open_ended_questions',
                    label:'Question 8',
                    value:'oe_question_8',
                    screen:PersonalizationOpenEndedQuestion,
                    screenData:{
                        questionOrder:4
                    }
                },
                {
                    parent:'open_ended_questions',
                    label:'Question 9',
                    value:'oe_question_9',
                    screen:PersonalizationOpenEndedQuestion,
                    screenData:{
                        questionOrder:5
                    }
                },
            ]
        },
    ];

    function handleSetScreen(item) {
        // Check if the active screen is a child of the clicked parent
        const isChildActive = item.items?.some(child => child.value === activeScreen?.value);

        if (activeScreen?.value === item.value || isChildActive) {
            // If the active screen is the parent or one of its children, set activeScreen to null
            setActiveScreen(null);
        } else {
            // Otherwise, set the active screen
            if (item?.items) {
                // If the parent has children, set the first child as active
                setActiveScreen(item?.items?.[0] ?? item);
            } else {
                // If no children, set the parent as active
                setActiveScreen(item);
            }
        }

        // Scroll to the top
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Adds smooth scrolling animation
        });
    }


    function isActiveScreen(item){
        return activeScreen?.parent === item.value || activeScreen?.value === item.value;
    }

    return <div>
        <div style={{marginBottom: '20px'}}>
            <Headline
                text={i18n.t("surveyPersonalization")}
                type={"Headline-personalization"}
            />
        </div>


        <div style={{
            display: "flex",
            flexDirection: "column",
            gap: '10px',

            borderLeft: "1px solid #61849D",
            marginLeft: "20px",
            padding: "0 0 0 20px"
        }}>
            {screens?.map((item, index) => {
                if(item?.hideScreen){
                    return;
                }
                return <div
                    key={index}
                    style={{
                        fontSize: "16px",
                        cursor: "pointer",
                        fontFamily: isActiveScreen(item) ? "HelveticaNeue-Bold" : "HelveticaNeueRegular",
                        fontWeight: "400",
                        fontStyle: "Regular",
                        letterSpacing: "0px",
                        color: isActiveScreen(item) ? "rgba(97, 132, 157, 1)" : "rgba(156, 156, 156, 1)",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: '5px',
                        }}
                         onClick={() => handleSetScreen(item)}
                    >
                        {item?.label}
                        {
                            item?.items ?
                                isActiveScreen(item) ?
                                    <img src={'/assets/ic_dropdown_active.svg'} alt={'arrow-small-right-hover'}/>
                                    :
                                    <img src={'/assets/ic_dropdown.svg'} alt={'arrow-small-right'}/>
                                :
                                isActiveScreen(item) ?
                                    <img src={'/assets/arrow-small-right-hover.svg'} alt={'arrow-small-right-hover'}/>
                                    :
                                    <img src={'/assets/arrow-small-right.svg'} alt={'arrow-small-right'}/>
                        }
                    </div>


                    {
                        (isActiveScreen(item) && item?.items) && <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: '10px',
                                paddingTop: '10px',
                                paddingLeft: "20px",
                            }}
                        >
                            {item?.items?.map((subItem, index) => {
                                if(subItem?.hideScreen){
                                    return;
                                }
                                return <div
                                    style={{
                                        fontFamily: isActiveScreen(subItem) ? "HelveticaNeue-Bold" : "HelveticaNeueRegular",
                                        color: isActiveScreen(subItem) ? "rgba(97, 132, 157, 1)" : "rgba(156, 156, 156, 1)",
                                    }}
                                    onClick={() => handleSetScreen(subItem)}
                                    key={index}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: '5px',
                                        }}
                                    >
                                        {subItem?.label}
                                        {
                                            isActiveScreen(subItem) ?
                                                <img src={'/assets/arrow-small-right-hover.svg'} alt={'arrow-small-right-hover'}/>
                                                :
                                                <img src={'/assets/arrow-small-right.svg'} alt={'arrow-small-right'}/>
                                        }
                                    </div>

                                </div>
                            })}
                        </div>
                    }


                </div>
            })}
        </div>

    </div>
}